import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["region", "province", "ubigeo"];

  connect() {
    //this.branchesTarget.disabled = false
  }

  region() {
    const id = event.target.value;
    const ajax_url = this.element.getAttribute("data-ubigeos-url");
    this.provinceTarget.innerHTML =
      '<option value=""> Cargando Provincias ...  </option>';
    this.ubigeoTarget.innerHTML = '<option value=""> Distrito: </option>';

    if (id) {
      Rails.ajax({
        type: "GET",
        url: ajax_url,
        data: `region_code=${id}`,
        success: (data) => {
          this.provinceTarget.innerHTML = '<option value=""> Provincia: </option>';
          this.ubigeoTarget.innerHTML = '<option value=""> Distrito: </option>';
          this.refreshDropdownValues(data, this.provinceTarget);
        },
        error: (data) => {
          console.log(`error ${data}`);
        },
      });
    }
  }

  province() {
    const id = event.target.value;
    const ajax_url = this.element.getAttribute("data-ubigeos-url");

    this.ubigeoTarget.innerHTML =
      '<option value=""> Cargando Distritos ... </option>';

    if (id) {
      Rails.ajax({
        type: "GET",
        url: ajax_url,
        data: `province_code=${id}`,
        success: (data) => {
          this.ubigeoTarget.innerHTML = '<option value=""> Distrito: </option>';
          this.refreshDropdownValues(data, this.ubigeoTarget);
        },
        error: (data) => {
          console.log(`error ${data}`);
        },
      });
    }
  }

  post() {
    const post_url = this.element.getAttribute("data-post-url");
    const ubigeo_id =
      this.ubigeoTarget.value ||
      this.provinceTarget.value ||
      this.regionTarget.value;
    if (ubigeo_id) {
      Rails.ajax({
        type: "POST",
        url: post_url,
        data: `ubigeo_id=${ubigeo_id}`,
        success: (data) => {
          console.log("Registrado");
        },
        error: (data) => {
          console.log(`error ${data}`);
        },
      });
    }
  }

  refreshDropdownValues(data, target) {
    target.disabled = false;
    for (var i = 0; i < data.length; i++) {
      var opt = data[i];
      target.innerHTML +=
        '<option value="' + opt.id + '">' + opt.name + "</option>";
    }
  }
}
