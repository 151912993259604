import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["remote_videos",
    "local_video",
    "record_button",
    "record_status",
    "mic_button",
    "camera_button",
    "join_button"
  ]

  static values = {
    isAnonymous: Boolean,
    currentCharacter: String,
    appointmentId: String
  }

  connect() {
    this.currentCharacter = this.currentCharacterValue
    this.isAnonymous = this.isAnonymousValue
    this.appointmentId = this.appointmentIdValue
    
    console.log("Conecting video chat....")
    console.log(this.currentCharacter)
    console.log(this.isAnonymous)
    console.log(this.appointmentId)
    
    this.pcPeers = {}
    this.recorders = {}
    this.videoRecordId = {}
    this.isRecording = false
    this.cameraOn = true
    this.micOn = true

    this.handleMutatedVideos = this.handleMutatedVideos.bind(this)
    this.handleRecorder = this.handleRecorder.bind(this)

    if (this.hasRecord_buttonTarget)
      this.recordStatusText = this.record_statusTarget

    this.observer = new MutationObserver(this.handleMutatedVideos)
    this.observer.observe(this.remote_videosTarget, { childList: true })

    this.ice = {
      "iceServers": [
        {
          urls: 'turn:pivot.pe:5349',
          credential: 'pivot1972.',
          username: 'pivot'
        },
      ]
    }

    this.CHUNK_TIME = 3000
    this.JOIN_ROOM = "JOIN_ROOM"
    this.EXCHANGE = "EXCHANGE"
    this.REMOVE_USER = "REMOVE_USER"
  }

  startJoining() {
    this.join_buttonTarget.lastChild.innerText = "uniéndose..."
    this.join_buttonTarget.disabled = true
    this.join_buttonTarget.classList.add('animate-pulse')

    this.stream().then(stream => {
      console.log("setting local camera and audio")
      this._localStream = stream

      this.local_videoTarget.srcObject = stream
      this.local_videoTarget.muted = true
      this.local_videoTarget.playsinline = true
      this.local_videoTarget.autoplay = true
      this.local_videoTarget.play()

    }).then(() => {
      console.log("creating subscription...")
      this.subscription()
      if ( this.isAnonymous ) {
        console.log("stopping camera and mic for anonymous...")
        this.stopCamera()
        this.stopMic() 
      }
    })
  }

  stream() {
    if (this._localStream == undefined) {
      return navigator.mediaDevices.getUserMedia(
        { 
          audio: true, 
          video: {
            width: { ideal: 640 },
            height: { ideal: 360 }
          }
        }
      )
    }
    return this._localStream
  }

  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
    this._localStream &
    this._localStream.getTracks().forEach( (track) => {
      track.stop()
    })
    this._localStream = undefined
  }

  subscription() {
    if (this._subscription == undefined) {
      let _this = this
      this._subscription = consumer.subscriptions.create({
          channel: "AppointmentChannel",
          appointment_id: _this.appointmentId
        }, {
        connected() {
          console.log("connected!!")
          this.send({ type: _this.JOIN_ROOM, from: _this.currentCharacter })
          _this.join_buttonTarget.classList.add("hidden")
          if (_this.hasCamera_buttonTarget)
            _this.camera_buttonTarget.classList.remove("hidden")
          if (_this.hasMic_buttonTarget)
          _this.mic_buttonTarget.classList.remove("hidden")
          if (_this.hasRecord_buttonTarget) 
            _this.record_buttonTarget.classList.remove("hidden")
        },
        disconnected() {
          this.send({ type: _this.REMOVE_USER, from: _this.currentCharacter })
        },
        received(data) {
          if (data.from === _this.currentCharacter) return
          console.log(`received ${data.type}`)
          console.log(data)
          switch (data.type) {
            case _this.JOIN_ROOM:
              return _this.startJoinRoom(data)
            case _this.EXCHANGE:
              if (data.to !== _this.currentCharacter) return
              return _this.exchange(data)
            case _this.REMOVE_USER:
              return _this.removeUser(data)
            default:
              return
          }
        },
      })
      //console.log(this._subscription)
      //this._subscription.consumer.connection.webSocket.binaryType = "arraybuffer"
    }
    return this._subscription
  }

  startJoinRoom(data) {
    this.createPC(data.from, true)
  }

  exchange(data) {
    let pc
    if (!this.pcPeers[data.from]) {
      console.log(`XCH createPc ${data.from}`)
      pc = this.createPC(data.from, false)
    } else {
      console.log(`XCH resuing this.pcPeers ${this.pcPeers[data.from]}`)
      pc = this.pcPeers[data.from]
    }

    if (data.candidate) {
      console.log(`XCH addIceCandidate ${data.candidate}`)
      pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate))).catch(e => {
        console.log("Failure during addIceCandidate(): " + e.name);
      })
    }

    if (data.sdp) {
      const sdp = JSON.parse(data.sdp)
      let _this = this
      console.log(`XCH data sdp ${sdp.type}`)

      pc.setRemoteDescription(new RTCSessionDescription(sdp))
        .then(() => {
          if (sdp.type === "offer") {
            pc.createAnswer()
              .then((answer) => {
                console.log(`XCH got answer ${answer}`)
                return pc.setLocalDescription(answer)
              })
              .then(() => {
                console.log(`XCH send from ${_this.currentCharacter} ${data}`)
                _this.subscription().send({
                  type: _this.EXCHANGE,
                  from: _this.currentCharacter,
                  to: data.from,
                  sdp: JSON.stringify(pc.localDescription)
                })
              })
          }
        })
    }
  }

  removeUser(data) {
    let video = document.getElementById(`video_${data.from}`)
    video && video.remove()
    delete this.pcPeers[data.from]
  }

  createPC(userId, isOffer) {
    console.log(`creating PC ${userId}`)
    let pc = new RTCPeerConnection(this.ice)
    this.pcPeers[userId] = pc
    let _this = this

    for (const track of _this._localStream.getTracks()) {
      pc.addTrack(track, _this._localStream)
    }

    isOffer && pc
      .createOffer()
      .then((offer) => {
        console.log(`setLocalDescription ${offer} `)
        return pc.setLocalDescription(offer)
      })
      .then(() => {
        console.log(`send localDescription offer exchange to ${userId} ${pc.localDescription} `)
        _this.subscription().send({
          type: _this.EXCHANGE,
          from: _this.currentCharacter,
          to: userId,
          sdp: JSON.stringify(pc.localDescription)
        })
      })


    pc.onicecandidate = (event) => {
      console.log(`send iceCandidate exchange to ${userId} ${event.candidate} `)

      event.candidate &&
        _this.subscription().send({
          type: _this.EXCHANGE,
          from: _this.currentCharacter,
          to: userId,
          candidate: JSON.stringify(event.candidate)
        })
    }

    pc.ontrack = (event) => {
      const id = `video_${userId}`
      let video = document.getElementById(id)
      const isAnonymous = userId.includes("anonymous")
      console.log(`on track! userId: ${userId}`)
      if (video == null) {
        video = document.createElement("video")
        video.id = id
        video.classList.add("rounded-md")
        if (isAnonymous){ 
          video.classList.add("hidden")
          console.log("hidden class for video")
        }
        video.autoplay = true
        video.playsinline = true
        video.controls = true 
        video.width = 480
        _this.remote_videosTarget.appendChild(video)
      }
      video.srcObject = event.streams[0]
      console.log(`on track! setting video ${id}`)
      video.play()
    }

    pc.oniceconnectionstatechange = () => {
      console.log(`iceconectionStateChange ${pc.iceConnectionState}`)
      if (pc.iceConnectionState == "disconnected") {
        _this.subscription().send({
          type: _this.REMOVE_USER,
          from: userId
        })
      }
    }
    return pc
  }
  

  //==== control buttons ====

  camera(event) {
    if (this.cameraOn) {
      this.stopCamera()
    } else {
      this.startCamera()
    }
  }

  startCamera() {
    this.cameraOn = true
    if (this.hasCamera_buttonTarget)
      this.camera_buttonTarget.classList.remove('off')
    this._localStream.getVideoTracks().forEach( (track) => {
      track.enabled = true
    })
  }

  stopCamera() {
    this.cameraOn = false
    this._localStream.getVideoTracks().forEach( (track) => {
      track.enabled = false
    })
    if (this.hasCamera_buttonTarget)
      this.camera_buttonTarget.classList.add('off')
  }

  mic(event) {
    if (this.micOn) {
      this.stopMic()
    } else {
      this.startMic()
    }
  }

  startMic() {
    this.micOn = true
    if (this.hasMic_buttonTarget)
      this.mic_buttonTarget.classList.remove('off')
    this._localStream.getAudioTracks().forEach( (track) => {
      track.enabled = true
    })
  }

  stopMic() {
    this.micOn = false
    this._localStream.getAudioTracks().forEach( (track) => {
      track.enabled = false
    })
    if (this.hasMic_buttonTarget)
      this.mic_buttonTarget.classList.add('off')
  }
  
  //=== video recording ==== 

  record(event) {
    if (!this.hasRecord_buttonTarget) return
    if (this.isRecording) {
      this.isRecording = false
      this.record_buttonTarget.firstChild.classList.remove("animate-ping")
      this.recordStatusText.innerText = "Grabar"
      this.subscription().perform("combine_videos")
    }
    else {
      this.isRecording = true
      this.record_buttonTarget.firstChild.classList.add("animate-ping")
      this.recordStatusText.innerText = "Iniciando grabación ..."
    }
    this.handleRecorder()
  }

  handleMutatedVideos(mutationsList, _observer) {
    if (!this.hasRecord_buttonTarget) return

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        this.handleRecorder()
      }
    }
  }

  handleRecorder(){
    if (!this.hasRecord_buttonTarget) return

    const videoElements = [
      ...this.remote_videosTarget.getElementsByTagName("video"), 
      this.local_videoTarget
    ]

    videoElements.forEach((el) => {
      if ( this.recorders[el.id] == undefined ) {
        console.log(`iniciando: ${el.id}`)
        this.recorders[el.id] = new MediaRecorder(el.srcObject, {
            mimeType: 'video/webm;codecs=vp8,opus'
        })
      }
      let recorder = this.recorders[el.id]
      switch (recorder.state) {
        case "inactive":
          if (this.isRecording){
            this.videoRecordId[el.id] = {
              counter: 0,
              timestamp: Date.now()
            }
            recorder.ondataavailable = event => this.videoRecordingHandler(el.id, event)
            recorder.start(this.CHUNK_TIME)
          }
          break

        // case "paused":
        //   recorder.resume()
        //   button.classList.remove("active")

        case "recording":
          if (!this.isRecording) recorder.stop()
          break;

        default:
          break;
      }

    })
  }

  videoRecordingHandler(videoId, event){
    if (!this.hasRecord_buttonTarget) return

    const reader = new FileReader()
    //reader.readAsArrayBuffer(event.data)
    reader.readAsDataURL(event.data)
    this.videoRecordId[videoId].counter ++
    const _this = this
    reader.onloadend = (event) => {
      const status = `Grabando... ${videoId} ${_this.videoRecordId[videoId].counter * _this.CHUNK_TIME / 1000 } s.`
      _this.recordStatusText.innerText = status
      console.log(status)
      _this.subscription().perform("record", { 
        chunk: reader.result, 
        id: videoId, 
        counter: _this.videoRecordId[videoId].counter,
        timestamp: _this.videoRecordId[videoId].timestamp
      })
    }
    reader.onstop = (event) => {
      const status = `Detenido ${videoId} ${_this.videoRecordId[videoId].counter * _this.CHUNK_TIME / 1000 } s.`
      _this.recordStatusText.innerText = status
      console.log(status)
    }
    reader.onwarning = (event) => {
      console.log(event)
    }
    reader.onerror = (event) => {
      console.log(event)
    }
  }
}
