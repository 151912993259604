import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'input',
    'count'
  ]
  
  connect() {
    this.compute()
  }

  compute() {
    const remainingCharacters = this.inputTarget.maxLength - this.inputTarget.value.length
    this.countTarget.textContent = remainingCharacters
  }

}
