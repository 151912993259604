import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]

  toggle(event) {
    
    this.elementTargets.forEach((el, i) => {
      el.classList.add("hidden")
    })
    
    if (event.target.checked) {
      this.elementTargets.forEach((el, i) => {
        if (el.getAttribute('data-key') == event.target.value)
          el.classList.remove("hidden")
      })
    }
  }
}