import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["element"];

  connect() {
    useClickOutside(this)
  }

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.toggle("hidden")
    });
  }

  hide(event) {
    this.element.querySelectorAll("[data-hidden]").forEach((el, i) => {
      if (el.dataset.hidden == "true") {
        el.classList.add("hidden")
      } else {
        el.classList.remove("hidden")
      }
    })
  }

  clickOutside(event) {
    if (this.element.dataset.noClickOuside == undefined)
      this.hide()
  }
}
